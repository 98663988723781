@font-face {
  font-family: 'tahoma';
  src: url('./fonts/tahoma.ttf') format('truetype');
}

@font-face {
  font-family: 'tahoma-bold';
  src: url('./fonts/tahoma-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'gotham';
  src: url('./fonts/Gotham-Font/GothamMedium.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'tahoma', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

